<template>
	<div>
		<v-stepper
			class="elevation-0"
			v-model="e6"
			vertical
			>
			<v-stepper-step
				:complete="e6 > 1"
				step="1"
				@click="e6 = 1"
				:rules="step1"
				>
				<span class="stepper-step-proceed">Account Information</span>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card elevation="0" class="mb-6">
					<v-container class="pa-0">
        <v-row dense>
            <v-col cols="12" class="d-flex align-items-center">
                <div class="font-weight-bold mr-2">Account Name:</div>
                <h3 class="ma-0">{{ accountName }}</h3>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" class="d-flex align-items-center">
                <div class="font-weight-bold mr-2">Legal Entity Name:</div>
                <h3 class="ma-0">{{ legalEntityName }}</h3>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" class="d-flex align-items-center">
                <div class="font-weight-bold mr-2">Trade Name:</div>
                <h3 class="ma-0">{{ tradeName }}</h3>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" class="d-flex align-items-center">
                <div class="font-weight-bold mr-2">BP ID No:</div>
                <h3 class="ma-0">{{ bpIdNo }}</h3>
            </v-col>
        </v-row>
    </v-container>
			</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="$router.push({path: '/sales-prospecting'})"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 2"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
						<!-- <v-btn
							@click="save(true)"
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Save As Draft</b>
						</v-btn> -->
					</v-col>
				</v-row>
			</v-stepper-content>
			<v-stepper-step
				:complete="e6 > 2"
				step="2"
				@click="e6 = 2"
				:rules="step2"
				>
				<span class="stepper-step-proceed">Score Card</span>
			</v-stepper-step>

			<v-stepper-content step="2">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<v-form ref="step2" v-model="step2form">
						<ScoreCard/>
					</v-form>
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 3"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
						<!-- <v-btn
							class="mr-4"
							@click="save(true)"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Save As Draft</b>
						</v-btn> -->
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 3"
				step="3"
				@click="e6 = 3"
				:rules="step3"
				>
				<span class="stepper-step-proceed">Supporting Documents</span>
			</v-stepper-step>

			<v-stepper-content step="3">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<supporting-documents :existing="true"/>
				</v-card>
				<v-divider class="my-5"></v-divider>
				<v-row>
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<!-- ONLY FOR TAGGING EXISTING PRPs-->
						<v-btn
							@click="save(false)"
							color="info"
							style="float:right;"
							>
							<b>Update Sales Prospect</b>
						</v-btn>

						<v-btn
							v-if="initialUploaded.upload_amla || initialUploaded.upload_kaizer"
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							@click="upload"
							>
							<b>Upload Documents</b>
						</v-btn>
						<v-btn
							v-else
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							disabled
							>
							<b>Upload Documents</b>
						</v-btn>

					</v-col>
				</v-row>
			</v-stepper-content>
		</v-stepper>	
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import jsonToFormData from 'json-form-data';
// import AccountInformation from '../components/modules/salesProspecting/AccountInformation.vue';
// import SalesPresentationInformation from '../components/modules/salesProspecting/ExistingSalesPresentation.vue'
import SupportingDocuments from '../components/modules/salesProspecting/SupportingDocuments.vue'
import ScoreCard from '../components/modules/scorecard/ScoreCard.vue';
export default {
	components: {
		ScoreCard: ScoreCard,
		// AccountInformation: AccountInformation,
		// SalesPresentationInformation: SalesPresentationInformation,
		SupportingDocuments: SupportingDocuments
	},
	async created () {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
		this.assignData(resSP.data.result[0])

	},
	data: () => ({ 
		e6: 1,
		step1: [],
		step2: [],
		step3: [],
		step1form: true,
		step2form: true,
		step3form: true,
		steps: ['step1', 'step2', 'step3'],
		partialSteps: ['step1', 'step2', 'step3'],
		existing: false,
		accountName:'',
		legalEntityName: '',
		tradeName: '',
		bpIdNo: '',
	}),
	computed: {
		...mapGetters({
			salesProspectingForm: 'salesProspecting/salesProspectingForm',
			spNumber: 'salesProspecting/spNumber',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded'
		}),
	},
	methods: {
		assignData (data) {
			this.decodeUploads(data.sp_primary_details)
			this.accountName = data.sp_primary_details.account_name,
			this.legalEntityName = data.sp_primary_details.legal_entity_name,
			this.tradeName = data.sp_primary_details.trade_name,
			this.bpIdNo = data.bp_idno
			// var salesProspectingForm = {
			// 	id: '',
			// 	account_name: data.sp_primary_details.account_name,
			// 	legal_entity_name: data.sp_primary_details.legal_entity_name,
			// 	trade_name: data.sp_primary_details.trade_name,
			// 	bp_idno: data.bp_idno,
        
			// 	// scorecard: JSON.parse(data.sp_primary_details.scorecard),

			// 	date_presentation: data.sp_primary_details.date_presentation,
			// 	documents_presented: data.sp_primary_details.documents_presented === null ? [] : JSON.parse(data.sp_primary_details.documents_presented),
			// 	summary_presentation: data.sp_primary_details.summary_presentation,
			// 	accept_or_negotiate: data.sp_primary_details.accept_or_negotiate,
			// 	// risk_profile: data.sp_primary_details.risk_profile,
			// 	uploaded_loi:  data.sp_primary_details.upload_loi === null ? [] : data.sp_primary_details.upload_loi,
			// 	uploaded_picture:  data.sp_primary_details.upload_picture === null ? [] : data.sp_primary_details.upload_picture,
			// 	uploaded_supp_docs:  data.sp_primary_details.upload_supp_docs === null ? [] : data.sp_primary_details.upload_supp_docs,
			// 	uploaded_kaizer: data.sp_primary_details.upload_kaizer === null ? [] : data.sp_primary_details.upload_kaizer,
			// 	uploaded_amla:  data.sp_primary_details.upload_amla === null ? [] : data.sp_primary_details.upload_amla,
			// 	amla_risk_rating: data.sp_primary_details.amla_risk_rating,
			// 	amla_risk_profile: data.sp_primary_details.amla_risk_profile,
			// }
			// console.log(salesProspectingForm, 'CHECK')
			// this.$store.commit('salesProspecting/SET_SELECTED_SP', salesProspectingForm)
			// console.log(this.salesProspectingForm, 'CHECK POPULATED')
		},
	saveNonAcceptance () {
			const self = this
			self.$store.commit('salesProspecting/SET_RULE_GENERIC')
			self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
			setTimeout(() => {
				self.partialSteps.forEach(e => {
					if (!self.$refs[e].validate()) {
						self[e] = [() => false]
						self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					} else {
						self[e] = []
					}
				})
				if (self.$refs.step1.validate()) {
					self.steps.forEach(e => {
						self[e] = []
					})
					// this.callSave(payload)
				}
			})
		},
		save (isDraft) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			// let finalPayload =  _.cloneDeep(this.salesProspectingForm);
			// finalPayload.scorecard_result = this.computeFinalRating()
      let origForm = {...this.salesProspectingForm};
			origForm.accept_or_negotiate = 'Accepts Partnership'
			origForm.account_name = this.accountName
			origForm.legal_entity_name = this.legalEntityName
			origForm.trade_name = this.tradeName
			origForm.bp_idno = this.bpIdNo
			origForm.id =  this.$route.params.id
			let finalPayload =  _.cloneDeep(origForm);
			if(this.computeFinalRating() >= 90){
				finalPayload.risk_profile = 'Low'
			} else if(this.computeFinalRating() >= 70 && this.computeFinalRating() < 90){
				finalPayload.risk_profile = 'Moderate'
			} else{
				finalPayload.risk_profile = 'High'
			}
			finalPayload.scorecard_result = this.computeFinalRating()
			finalPayload.is_draft = isDraft
			var convertedFormData = jsonToFormData(finalPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}			
			if (isDraft) {
				this.callSave(payload)
			} else {
				const self = this
				self.$store.commit('salesProspecting/SET_RULE_GENERIC')
				self.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				// self.$store.commit('salesProspecting/SET_RULE_SCORECARD')
				setTimeout(() => {
					self.steps.forEach(e => {
						if (e === 'step3') {
							//Added Condition for AML and Kaizer
							if (self.existingInitialUploaded.upload_amla && self.existingInitialUploaded.upload_kaizer) {
								if(self.salesProspectingForm.amla_risk_rating != ""){
									this.callSave(payload)
								}else{
									//Display Error if AML Rating is left blank
									self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'AML Rating field should be filled out!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
								}
							} else {
								self.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'File Uploads are not complete!','messagetype':'error'},{ root: true });
								self[e] = [() => false]
							}
						}
					})
				})
			}
		},
		async callSave (payload) {
			const res = await this.$store.dispatch('salesProspecting/doSaveSalesProspecting', {payload})
			if(res.status === 200) {
					this.$store.dispatch('salesProspecting/doUpdateExistingSalesProspecting', this.$route.params.id)
				this.$store.commit('salesProspecting/SET_RULE_GENERIC')
				this.$store.commit('salesProspecting/SET_RULE_CHECKBOX')
				this.$store.commit('salesProspecting/SET_RULE_SCORECARD')
				this.$router.push('/existing-sales-prospecting');
			}
		},
		computeFinalRating () {
			if(typeof this.salesProspectingForm.scorecard !== 'string'){
				var total = 0
				this.salesProspectingForm.scorecard.forEach(function(e){
					e.criteria.forEach(function(f){
						total += (parseInt(f.score) * (f.weight / f.metrics.length).toFixed(2))
					})
				})
				if(isNaN(total)){
					return ''
				}
				return total.toFixed(2)
			}
		},
		//HANDLE UPLOADING OF SUPPORTING
		async upload () {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let finalPayload =  _.cloneDeep(this.initialUploaded);
			var convertedFormData = jsonToFormData(finalPayload, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('salesProspecting/doInitialUpload', {payload})
			console.log(res)
			
			this.reloadUploads()
		},
		async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
			this.decodeUploads(res.data.result[0].sp_primary_details)
			this.$store.commit('salesProspecting/SET_DEFAULT_INITIAL_UPLOADED_FILES')
		},
		//SETTING OF UPLOADED SUPPORTING DOCUMENTS
		decodeUploads (resUpload) {
			if (resUpload.upload_loi !== null) {
				resUpload.upload_loi = JSON.parse(resUpload.upload_loi)
			}
			if (resUpload.upload_picture !== null) {
				resUpload.upload_picture = JSON.parse(resUpload.upload_picture)
			}
			if (resUpload.upload_supp_docs !== null) {
				resUpload.upload_supp_docs = JSON.parse(resUpload.upload_supp_docs)
			}
			if (resUpload.upload_amla !== null) {
					resUpload.upload_amla = JSON.parse(resUpload.upload_amla)
				}
				if (resUpload.upload_kaizer !== null) {
					resUpload.upload_kaizer = JSON.parse(resUpload.upload_kaizer)
				}
			this.$store.commit('salesProspecting/SET_EXISTING_INITIAL_UPLOADED_FILES', resUpload)
		},

	}
};
</script>
<style scoped>
	.stepper-step-proceed {
		cursor: pointer;
	}
	.stepper-step-forbidden {
		cursor: no-drop;
	}
</style>